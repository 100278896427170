import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Image, { FixedObject } from "gatsby-image";

import { SiteSiteMetadataAuthor } from "graphql-types";
import { rhythm } from "../../utils/typography";
import { yearsSince } from "../../utils/timeSince";
import { device } from "../../styles/constants";
import { useAvatar } from "../../hooks";

interface AboutProps {
  author: SiteSiteMetadataAuthor;
}

const About: React.FunctionComponent<AboutProps> = ({
  author,
}): React.ReactElement => {
  const avatar = useAvatar({ width: 200, height: 200 });

  return (
    <Root>
      <Avatar fixed={avatar.childImageSharp.fixed as FixedObject} />

      <Description>
        <h4>About Me</h4>
        <p>
          I am a passionate software engineer and leader with over 20 years of
          experience. I build and inspire my teams to have shared ownership of
          the vision, process and product. Innovative products emerge through
          team communication, collaboration and focus.
        </p>

        <p>
          I love building software and teams, my philosophy is “Work hard and be
          kind.”
        </p>

        <p>
          Software development is all about balancing the pros and cons of
          different potential solutions to come up with an implementation
          optimized for the specific problem. No one person has all the answers,
          therefore it is crucial for a team to collaborate with inclusion and
          respect to achieve the best possible outcome. Successful development
          teams have a shared vision, are open to new ideas, and when they can’t
          get consensus they disagree and commit to move forward.
        </p>

        <p>
          I believe in creating a culture of:
          <ul>
            <li>Honesty, Transparency and respect towards everyone</li>
            <li>Inclusion and acceptance of all backgrounds and ideas</li>
            <li>Shared ownership with individual accountability</li>
          </ul>
        </p>
        <p>
          My job as a manager:
          <ul>
            <li>
              Attract, retain and grow high performing individuals and teams
            </li>
            <li>
              Set context, clarify goals, give and solicit direct feedback
            </li>
            <li>
              Foster an environment of mutual trust and safety to speak candidly
            </li>
            <li>
              Support my reports to help them be as successful as possible
            </li>
          </ul>
        </p>

        <h4>More About Me</h4>
        <p>
          I love spending my free time outdoors with my wife and daughter. We
          especially love riding our bikes to the beach and hiking in the
          mountains. I’m a country boy at heart and enjoy spending time at my
          Family’s ranch in Northern California, working on and driving my 1973
          International Harvester Scout.
        </p>
        <p>
          I am passionate about renewable energy and I love to build{" "}
          <a href="https://www.masteredsoftware.com/games">indie games</a>,
          mobile apps such as{" "}
          <a href="https://mojitxt.com/">mojitxt – the emoji based chat game</a>
          , and open source software.
        </p>
        <p>
          Do you want to find out more about me? Please checkout my{" "}
          <em>
            <Link to="/resume">resume</Link>.)
          </em>
        </p>
      </Description>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-gap: ${rhythm(2)};
  margin-top: ${rhythm(0.5)};

  @media ${device.tablet} {
    grid-template-columns: minmax(20%, 200px) 70%;
  }
`;

const Avatar = styled(Image)`
  align-self: center;

  border-radius: 50%;
  width: 150px;
  height: 150px;
  justify-self: center;
  align-self: flex-start;

  @media ${device.tablet} {
    width: auto;
    height: auto;
    justify-self: start;
  }
`;

const Description = styled.section`
  h4 {
    margin-top: ${rhythm(0.5)};
  }
`;

export default About;
